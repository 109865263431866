import type { FC } from "react";
import StarRatingComp from "../StarRatingCom";
import { Style } from "../../utils/Style";

interface SkillRatingProps {
  skill: any;
  title: string;
  setData: any;
}

const SkillRating: FC<SkillRatingProps> = ({ skill, title, setData }) => {
  return (
    <table className="table-fixed w-full mt-2">
      <thead className="text-sm">
        <tr>
          <th className="w-1/2">
            <p className="p-2 flex justify-start text-xs">{title}</p>
          </th>
          <th className="w-1/2">
            <p className="p-2 flex justify-start text-xs">Rating</p>
          </th>
          <th className="w-1/2">
            <p className="p-2 flex justify-start text-xs">Comments</p>
          </th>
        </tr>
      </thead>
      <tbody className="text-gray-400 ">
        {skill.map((e: any, index: any) => {
          return (
            <tr className="bg-gray-50 border-b-2 border-white" key={index}>
              <td>
                <p className="px-2 py-1 text-xs text-gray-500">
                  {e.skill_name}
                </p>
                <p className="px-2 text-xs font-thin text-primary mb-2">
                  Weightage: {e.weightage}%
                </p>
              </td>
              <td>
                <p className="p-2">
                  <div className="flex flex-row gap-3 items-center justify-start ">
                    <StarRatingComp
                      stars={e.skill_rating}
                      callBack={(rate: any) => {
                        setData((prevItems: any) =>
                          prevItems.map((item: any) =>
                            item.skill_id === e.skill_id
                              ? { ...item, skill_rating: rate }
                              : item
                          )
                        );
                        setData((prevItems: any) =>
                          prevItems.map((item: any) =>
                            item.skill_id === e.skill_id && rate > 0
                              ? { ...item, rating_err: false }
                              : item
                          )
                        );
                      }}
                    />
                    <p className="text-xs text-gray-500">{e.skill_rating}</p>
                  </div>
                </p>
                {e.rating_err && (
                  <div className="error">{"Select Rating*"}</div>
                )}
              </td>
              <td>
                <textarea
                  placeholder="Enter your Comments"
                  className={Style.iInput}
                  rows={2}
                  cols={40}
                  id="experience"
                  name="experience"
                  value={e.experience}
                  onChange={(v: any) => {
                    setData((prevItems: any) =>
                      prevItems.map((item: any) =>
                        item.skill_id === e.skill_id
                          ? { ...item, experience: v.target.value }
                          : item
                      )
                    );
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default SkillRating;
