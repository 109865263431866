import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import orgApi from "../../api/org_api";
import { Style } from "../../utils/Style";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

type UserProp = { setLoading: Function };

const UserManagement: React.FC<UserProp> = ({
  setLoading,
}: UserProp): JSX.Element => {
  const [showModal, setShowModal] = React.useState(false);
  const [team, setTeam] = React.useState([]);
  const [phErrorMsg, setPhErrorMessage] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);

  const formik = useFormik({
    initialValues: {
      email: "",
      team_name: "",
      first_name: "",
      last_name: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email Id is required")
        .email("Invalid Email"),
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string(),
      team_name: Yup.string().required("Team name is required"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      var ph;
      if (
        phone !== undefined &&
        countryCode !== "" &&
        countryCode !== undefined
      ) {
        ph = phone.replace("+" + countryCode, "");
        if (ph !== "") {
        } else {
          setPhErrorMessage("Please enter the valid Phone number");
          return;
        }
      } else {
        setPhErrorMessage("Please enter the valid Phone number");
        return;
      }
      setLoading(true);
      const reqD = {
        email: values.email.toLowerCase(),
        mobile: ph!,
        first_name: values.first_name,
        last_name: values.last_name,
        team_name: values.team_name,
        mobile_country_code: countryCode,
      };
      const res = await orgApi.addRec(org.id, reqD);
      if (res.data.success) {
        setShowModal(false);
        toast("Invite sent successfully");
        getTeam();
      } else {
        toast(res.data.msg);
      }
      setLoading(false);
    },
  });

  const addInterviewer = () => {
    formik.resetForm();
    setShowModal(true);
  };

  React.useEffect(() => {
    getTeam();
  }, []);

  const getTeam = async () => {
    setLoading(true);
    const res = await orgApi.getRec(org.id);
    res.data.success && setTeam(res.data.teams);
    setLoading(false);
  };

  function handlechange(e: any) {
    setPhErrorMessage("");
    setPhone(e);
    if (e !== undefined && countryCode !== "") {
      const ph = e.replace("+" + countryCode, "");
      !ph && setPhErrorMessage("Please enter the valid Phone number");
    } else {
      setPhErrorMessage("Please enter the valid Phone number");
    }
  }
  function handleBlur(e: any) {
    setPhErrorMessage("");
    const len = e.target.value.split(" ").length;
    const c = e.target.value.split(" ");
    const cc = c[0].split("+");
    setCountryCode(cc[1]);
    const ph = e.target.value.substring(e.target.value.indexOf(" ") + 1);
    if (len > 1 && ph !== "") {
    } else {
      setPhErrorMessage("Please enter the valid Phone number");
    }
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {showModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-xl p-4 mx-auto bg-white shadow-lg rounded-br-3xl">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm 2xl:text-lg font-bold text-gray-800 mb-2">
                        Add Recruiter
                      </h6>
                      <button
                        onClick={() => setShowModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm 2xl:text-base w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="mt-1">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1">
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <label className={Style.label}>First Name*</label>
                            <input
                              placeholder="First Name"
                              type="text"
                              id="first_name"
                              name="first_name"
                              value={formik.values.first_name}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className={
                                formik.touched.first_name &&
                                formik.errors.first_name
                                  ? Style.iError
                                  : Style.iInput
                              }
                            ></input>
                            {formik.touched.first_name &&
                              formik.errors.first_name && (
                                <div className="error">
                                  {formik.errors.first_name}
                                </div>
                              )}
                          </div>
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <label className={Style.label}>Last Name</label>
                            <input
                              placeholder="Last Name"
                              type="text"
                              id="last_name"
                              name="last_name"
                              value={formik.values.last_name}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className={
                                formik.touched.last_name &&
                                formik.errors.last_name
                                  ? Style.iError
                                  : Style.iInput
                              }
                            ></input>
                          </div>
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <label className={Style.label}>Email*</label>
                            <input
                              placeholder="Email"
                              type="text"
                              id="email"
                              name="email"
                              value={formik.values.email}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className={
                                formik.touched.email && formik.errors.email
                                  ? Style.iError
                                  : Style.iInput
                              }
                            ></input>
                            {formik.touched.email && formik.errors.email && (
                              <div className="error">{formik.errors.email}</div>
                            )}
                          </div>
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <label className={Style.label}>Phone*</label>
                            <PhoneInput
                              defaultCountry="IN"
                              international
                              countryCallingCodeEditable={false}
                              initialValueFormat="national"
                              name="phone"
                              value={phone}
                              onBlur={handleBlur}
                              onChange={handlechange}
                              className={
                                phErrorMsg !== "" ? Style.iError : Style.iInput
                              }
                              autoComplete="off"
                            />
                            {phErrorMsg && (
                              <div className="error">{phErrorMsg}</div>
                            )}
                          </div>
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <label className={Style.label}>Team Name*</label>
                            <input
                              placeholder="Team Name"
                              type="text"
                              id="team_name"
                              name="team_name"
                              value={formik.values.team_name}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className={
                                formik.touched.team_name &&
                                formik.errors.team_name
                                  ? Style.iError
                                  : Style.iInput
                              }
                            ></input>
                            {formik.touched.team_name &&
                              formik.errors.team_name && (
                                <div className="error">
                                  {formik.errors.team_name}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="gap-2 mt-3 sm:flex md:flex lg:flex justify-center text-sm 2xl:text-lg">
                          <button
                            className="mt-2 py-1.5 px-2.5 text-gray-800 outline-none rounded-lg border bg-gray-100"
                            onClick={() => setShowModal(false)}
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            className="mt-2 py-1.5 px-2.5 text-white button-primary outline-none rounded-lg"
                            type="submit"
                          >
                            Invite
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col relative" style={{ height: "86vh" }}>
        <button
          type="button"
          onClick={() => addInterviewer()}
          className="p-2 text-white bg-primary text-sm 2xl:text-base rounded-md w-16 absolute top-0 right-0"
        >
          + Add
        </button>

        <div className="m-2 overflow-y-auto">
          <table className="table-fixed text-sm 2xl:text-base w-full">
            <thead>
              <tr>
                <th align="left" className="w-1/2">
                  <p className="p-2">Name</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Email</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Contact Number</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Team</p>
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600">
              {team.map((value: any) => (
                <tr className="bg-gray-50 border-b-2 border-white ">
                  <td>
                    <p className="p-2">
                      {value.first_name
                        ? value.first_name + " " + value.last_name
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <p className="p-2">{value.email}</p>
                  </td>
                  <td>
                    <p className="p-2">
                      +{value.mobile_country_code} {value.mobile}
                    </p>
                  </td>
                  <td>
                    <p className="p-2">{value.team_name}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default UserManagement;
